.filterArea {
    font-size: large;
    padding-left: inherit;
}

.hide_cover_powerKLibrary {
    display: none;
}
.TPalerts-close-btn svg{
    font-size: 24px !important;
    margin-left: 20px;
    padding: 3px;
}

.powerKLibrary_cover {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    width: 100%;
    z-index: 8;
}

.powerKLibrary_container {
    position: relative;
    width: 100%;
}

.status_pill {
    border-radius: 50px;
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.5rem;
    width: 6rem;
    align-items: center;
    justify-content: center;
}

.status_pill_new:hover {
    background-color: #9897A6;
}

.status_pill_new {
    border: 2px solid #F3F3F5;
    border-color: #2E2E38;
}

.status_pill_in_progress:hover {
    background-color: #D2E1FA;
}

.status_pill_in_progress {
    border: 2px solid #1275C1;
}

.status_pill_processed:hover {
    background-color: #F2D9E8;
}

.status_pill_processed {
    border: 2px solid #B14891;
}

.status_pill_prompted:hover {
    background-color: #9c82d429;
}

.status_pill_prompted {
    border: 2px solid #724bc3;
}

.status_pill_published:hover {
    background-color: #E7FCE8;
}

.status_pill_published {
    border: 2px solid #1F893F;
}

.status_pill_completed:hover {
    background-color: #E7FCE8;
}

.status_pill_completed {
    border: 2px solid #1F893F;
}

.status_pill_skipped:hover {
    background-color: #FCD9BC;
}

.status_pill_skipped {
    border: 2px solid #B26100;
}

.status_pill_failed:hover {
    background-color: #FCD7CF;
}

.status_pill_failed {
    border: 2px solid #EA011D;
}

.commonTitleEllipses_powerKLibrary {
    word-break: break-word;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.showEllipses_powerKLibrary {
    overflow: hidden;
    text-overflow: ellipsis;
}

.divTxtSubmenu_powerKLibrary {
    max-width: 100%;
}

.common_container_subtitle_powerKLibrary {
    border-bottom: none;
    margin: 0px 40px;
    /* text-transform: capitalize; */
    padding-bottom: 6px;
    /* height: 55px; */
}

.common_container_rightMenu_powerKLibrary {
    font-weight: 600;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    margin-top: 25px;
    justify-content: space-between;
    padding-bottom: 5px;
    width: auto;
}

.divTxtSubmenu_powerKLibrary_Right {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.icon-margin-right {
    margin-right: 3px; /* Adjust the value as needed */
  }

.library_dropdownmenu{
    display: none;
}
.library_dropdown-button {
    display: none;
}
.library_dropdown-content{
    display: none;
}
.common_container_rightMenu_powerKLibrary p {
    /* margin-top: 25px; */
    margin-bottom: 0 !important;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.backToLibrarySearchLink_PKLibrary .linkStyleDetails_powerKLibrary svg {
    padding-right: 4px;
    font-size: 18px;
    padding-top: 2px;
}

.linkStyleDetails_powerKLibrary {
    color: black;
    text-decoration: none;
}

.dataBaseLink_powerKLibrary {
    padding-right: 10px;
}

.dataBaseLink_powerKLibrary .databaseLinkStyleDetails_internalApp {
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}

.dataBaseLink_powerKLibrary .databaseLinkStyleDetails_internalApp svg {
    margin-top: 2px;
    margin-right: 4px;
}

.common_container_ribbon-container {
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 40px;
    /* text-transform: capitalize; */
    /* padding-bottom: 6px; */
    padding: 4px 12px;
    align-items: center;
    background: #f6f6fa;
    /* border: 1px solid #ccc; */
}

.common_container_ribbon-container .button-without-border {
    color: #0d6efd;
    font-weight: 600;
    font-size: 12px;   
}

/* .common_container_ribbon-container .button-without-border span {
    font-weight: 500;
    font-size: 14px;
} */

.ribbon-text {
    font-size: 14px;
    font-weight: 400;
}

.toggle-button {
    padding: 5px 10px;
    cursor: pointer;
}

.content-div {
    margin-top: 10px;
    background: #ddd;
    padding: 10px;
    border: 1px solid #ccc;
}

.library_content .detailslist_wrapper {
    max-height: 56vh;
}

.datagrid_menucontainer .clearSelection .clearSelection-clearBtn {
    font-size: 22px;
    padding: 2px;
    margin-top: 2px;
}

/* .library_content .ms-DetailsRow {
    pointer-events: none;
} */

/* .library_content .ms-DetailsRow-cellCheck {
    pointer-events: all;
} */

.backToLibrarySearchLink_PKLibrary .linkStyleDetails_powerKLibrary {
    color: #0d6efd;
    align-items: center;
    display: flex;
    text-transform: none;
    font-weight: 600;
    font-size: 16px;
    width: auto;
}

.fieldText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.fieldText.expanded {
    overflow: visible;
    text-overflow: unset;
    display: -webkit-box;
    -webkit-line-clamp: inherit;
    -webkit-box-orient: vertical;
}

.truncate {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.expand {
    display: block;
}

button.ReadContent {
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    font-weight: 600;
}
.seeMoreSectionClass {
    display:block;
    max-height: 120px; /* Set your desired height */
    overflow: auto !important;
    border-bottom: none;
    display: flex;   
    align-items: flex-start;
    margin: 0px 40px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    flex-direction: column;
    background: #f6f6fa;  
    
}
.seeMoreOuterClass{
    width:100%
}


.seeMoreSectionInnerClass{
    display: flex;
    flex-direction: row;
    width :100%;
    justify-content: space-between;
    font-size: 14px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right:10px;
   
   }
   .seeMoreSectionFirstThree{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:85%;
    align-items: flex-start;  

   }
   .seeMoreSectionInnerClass .seeMoreFieldName{
    font-weight: 700; 
    width:20% 
    }
    .seeMoreSectionInnerClass .seeMoreOperand{
        text-align: left;
        width:10%;
     
    }

.seeMoreSectionInnerClass .seeMoreValue{
    line-height: 1.5;
    text-align: left;
    width:70%;
    line-height: 1.5;
 
}

.seeMoreSectionInnerClass  .seeMoreCount
{
    width:15%;
    text-align: right;
}

.powerKLibrary_container .seperateBorder{
    display: none;
}


.uploadReferenceGuideDialogbox .ui-dialog__footer .ui-buttons {
    background-color: transparent !important;
    opacity: 1 !important;
  }

  .upload_reference_guide_container {
    padding-right: 20px;
  }

  .upload_reference_guide_container .btn_upload_reference_guide {
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: rgba(13,110,253);
    border: none;
    background-color: transparent;
  }

  .upload_reference_guide_container .btn_upload_reference_guide:hover{
    color: #0a58ca;
  }
  .btn_upload_reference_guide:disabled, .btn_upload_reference_guide:disabled:hover{
    color: grey;
  }

  /* Common styles */
.DropzoneBodyUploadReferenceGuide {
    text-align: center;
    padding: 30px;
    border-style: dashed;
    border-width: 2px;
    border-color: #eeeeee;
    background-color: #fafafc;
    margin-bottom: 20px;
  }
  
  .DropzoneBodyDisabledUploadReferenceGuide {
    color: grey;
    cursor: initial;
  }

  .uploadButton_UploadReferenceGuide{
    font-size: 30px;
  }
/* @media only screen and (min-width: 548px) and (max-width: 768px)
{
    .seeMoreSectionInnerClass{
        display: flex;
        flex-direction: row;
        width :100%;
        justify-content: space-between;
        font-size: 14px;        
        padding-left: 10px;
             
       }

    .seeMoreSectionInnerClass  .seeMoreCount
    {
    width:15%;
    text-align: right;
    }
    .seeMoreSectionFirstTwo{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width:85%;
        align-items: flex-start;  
    
       }

} */
@media only screen and (max-width: 925px)
{
    .seeMoreOuterClass{

        width:120%
    }   
}
@media only screen and (max-width: 768px)
{
    .seeMoreOuterClass{

        width:200%
    }   
    .common_container_subtitle_powerKLibrary {
        margin: 0px 15px;
    }
}
@media only screen and (max-width: 510px)
{
    .seeMoreSectionClass {
        display:block;
        max-height: 120px; /* Set your desired height */
        overflow: auto !important;
        border-bottom: none;
        display: flex;   
        align-items: flex-start;
        margin: 0px 10px;
        padding: 4px 5px;
        flex-direction: column;
        background: #f6f6fa;       
        
    }
    .seeMoreSectionInnerClass{
        display: flex;
        flex-direction: row;
        width :100%;
        justify-content: space-between;
        font-size: 12px;      
                    
       }

    .seeMoreSectionInnerClass  .seeMoreCount
    {
    width:15%;
    text-align: right;
    }
    .seeMoreSectionFirstThree{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width:85%;
        align-items: flex-start;  
    
       }
       .seeMoreSectionInnerClass .seeMoreFieldName{
        font-weight: 700; 
        width:20% 
    }
    .seeMoreSectionInnerClass .seeMoreValue{
        line-height: 1.5;
        text-align: left;
        line-height: 1.5;
    } 

    .common_container_ribbon-container {
        border-bottom: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px 10px;
        /* text-transform: capitalize; */
        /* padding-bottom: 6px; */
        padding: 4px 12px;
        align-items: center;
        background: #f6f6fa;
        /* border: 1px solid #ccc; */
    }


}

.uploadExcelAttachments {
    display: flex;
    gap: 15px;
  }

  .ProgressbarHeader_UploadReferenceGuide {
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
    text-indent: 4px;
    max-width: 54ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .DatadropBody_UploadReferenceGuide {
    width: 90%;
    margin: 0px 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .detailLinkFlex{
    display: flex;
    align-items: flex-start;
  }
  .icon-column {
    flex-direction: column; /* Arrange items in a column */
    align-items: center; /* Center the icon horizontally */
    justify-content: center; /* Center the icon vertically */
    flex: 0 0 auto; /* Prevents the icon column from growing */
    margin-right: 8px;
  }
  
  .content-column {
    display: flex;
    align-items: center; /* Center the content horizontally */
    justify-content: center;
    flex: 1 1 auto; /* Allows the content column to grow */
  }

  .icon-column>svg{
    color: red;
    height: 1.4em;
    width: 1.4em;
  }

  .ErrorIconStyle {
    font-size: 20px;
  }
  .ErrorMessage_IncorrectFileType>svg {
    color: rgb(196, 49, 75);
    margin-top: 3px;
  }
  .ErrorMessage_IncorrectFileType>span {
    font-size: 16.6px;
  }
  .ErrorMessage_IncorrectFileType {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-indent: 5px;
    padding-top: 5px;
  }

@media only screen and (max-width: 450px)
{
.common_container_ribbon-container .button-without-border {
    color: #0d6efd;
    font-weight: 700;
    font-size: 16px;   
}
.ribbon-text {
    font-size: 12px;
    font-weight: 400;
    color: #0d6efd;
}
.seeMoreOuterClass{

    width:220%
}
.dataBaseLink_powerKLibrary {
    padding-right: 0px;
    width: 95px;
}

.upload_reference_guide_container {
    padding-right: 10px;
    width: 95px;
}
}
.reportDetailsViewPage {
    margin-left: 25px;
}
@media only screen and (min-width: 320px) and (max-width: 768px){
    .databaseLinkStyleDetails_internalApp{
        font-size: 14px !important;
    }

    .divTxtSubmenu_powerKLibrary_Right {
        display: none;
    }
      
    .library_dropdownmenu {
        position: absolute;
        display: inline-block;
        z-index: 3;
        right:0;
        border-radius: 8px;
        min-width: 100px;
        min-height: 40px;
        background-color: #fff;
        margin-top: 8px;
    }

    .library_dropdown-button {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    .library_dropdownHeader{
        margin-left: 10px;
        margin-top: 5px;
    }

    .library_dropdown-content {
        position: absolute;
        background-color: #f9f9f9;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

    .library_dropdown-content .databaseLinkStyleDetails_internalApp {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .library_dropdown-content .databaseLinkStyleDetails_internalApp:hover {
        background-color: #f1f1f1;
    }
      
    .show {
        display: block;
        position: absolute;
        left: -110px;
    }

    .menuoption_upload_reference_guide_container .menuoption_upload_reference_guide {
        padding: 12px 16px;
        display: block;
        border: none;
        font-size: 14px;
        font-weight: 600;
        background: transparent;
    }

    .menuoption_upload_reference_guide_container .menuoption_upload_reference_guide:hover {
        background-color: #f1f1f1;
    } 
}

/* .reportDetailsViewPage .displayAreaContainer {
    position: initial;
    height: 100vh;
} */

.exportDialogbox .ui-radiogroup {
    margin-bottom: 1rem;
}
.exportDialogbox .UpdateDataBaseCheckbox {
  width: 100% !important;
  opacity: 1 !important;
  background: transparent !important;
}
.exportDialogbox .ui-radiogroup__item__indicator {
  color: #1A1A24;
}
.exportDialogbox .ui-checkbox[aria-checked="true"] .ui-checkbox__indicator {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' role='presentation' style='background-color: black; padding: 2px;' focusable='false' viewBox='8 8 22.5 22.5'><g><path fill='%23fff' d='M23.5 11.875a.968.968 0 0 1-.289.711l-8.25 8.25c-.192.193-.43.289-.711.289s-.519-.096-.711-.289l-4.75-4.75a.965.965 0 0 1-.289-.711c0-.125.027-.25.082-.375s.129-.234.223-.328a.953.953 0 0 1 .695-.297c.135 0 .266.025.391.074.125.05.231.121.32.215l4.039 4.047 7.539-7.547a.886.886 0 0 1 .32-.215c.125-.049.255-.074.391-.074a1.004 1.004 0 0 1 .922.625.97.97 0 0 1 .078.375z' /></g></svg>") !important;
}
.exportDialogbox .ui-radiogroup__item {
  width: auto;
  min-height: auto;
  background-color: transparent;
  opacity: 1;
}
.dialogDeleteStylesInternal .ui-dialog__footer .ui-flex {
    background-color: transparent !important;
    opacity: 1 !important;
}
.dialogHeaderInternal>h2 {
    font-size: 16px;
    font-weight: 600 !important;
}
.dialogHeaderInternal .ui-dialog__content p {
  margin: 1rem 0px;
}

.dialogHeaderInternal .ui-dialog__content {
  margin: 0;
}
.dialogHeaderInternal .ui-dialog__footer {
  margin-top: 24px;
  margin-bottom: 17px;
  background: transparent !important;
  width: 100%;
  opacity: 1 !important;
}
.dialogBoxInternal {
  height: auto !important;
}

.dialogBoxInternal .ui-dialog__header {
  width: auto;
  min-height: auto;
  background-color: transparent;
  opacity: 1;
}
.dialogBoxInternal .ui-dialog__footer .ui-buttons {
  background-color: transparent !important;
  opacity: 1 !important;
}
.dialogBoxInternal .ui-dialog__footer .ui-buttons .ui-flex .ui-button {
  padding: 0px 16px !important;
  background-color: #ffe600;
  color: #2e2e38 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  border: 1px solid #2e2e38 !important;
  transition: all 0.1s !important;
  height: 34px !important;
  font-family: inherit !important;
  text-align: center !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  opacity: 1 !important;
}
.dialogBoxInternal .ui-dialog__footer .ui-buttons .ui-flex button:first-child {
  background-color: rgb(255, 255, 255) !important;
}