.mtextInputStyles input , .mtextInputStyles input:focus {
  background-color: #f6f6fa !important;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-color: transparent;
}

.filteredColumnResultsCheckBox {
    display:flex;
    flex-direction: column; 
    max-height: 180px; 
    overflow: auto !important;
}

.checkBoxDiv .checkBoxClass {
  margin-top: 4px;
  width: 100%;
  
}

.checkBoxDiv .checkBoxClass[aria-checked="true"] .ui-checkbox__indicator {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' role='presentation' style='background-color: black; padding: 2px;' focusable='false' viewBox='8 8 22.5 22.5'><g><path fill='%23fff' d='M23.5 11.875a.968.968 0 0 1-.289.711l-8.25 8.25c-.192.193-.43.289-.711.289s-.519-.096-.711-.289l-4.75-4.75a.965.965 0 0 1-.289-.711c0-.125.027-.25.082-.375s.129-.234.223-.328a.953.953 0 0 1 .695-.297c.135 0 .266.025.391.074.125.05.231.121.32.215l4.039 4.047 7.539-7.547a.886.886 0 0 1 .32-.215c.125-.049.255-.074.391-.074a1.004 1.004 0 0 1 .922.625.97.97 0 0 1 .078.375z' /></g></svg>") !important;
  border-left-color: rgb(34, 15, 15) !important;
  border-right-color: black !important;
  border-top-color: black !important;
  border-bottom-color: black !important;
}

.checkBoxDiv .checkBoxClass[aria-checked="true"] .ui-checkbox__indicator:hover {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' role='presentation' style='background-color: black; padding: 2px;' focusable='false' viewBox='8 8 22.5 22.5'><g><path fill='%23fff' d='M23.5 11.875a.968.968 0 0 1-.289.711l-8.25 8.25c-.192.193-.43.289-.711.289s-.519-.096-.711-.289l-4.75-4.75a.965.965 0 0 1-.289-.711c0-.125.027-.25.082-.375s.129-.234.223-.328a.953.953 0 0 1 .695-.297c.135 0 .266.025.391.074.125.05.231.121.32.215l4.039 4.047 7.539-7.547a.886.886 0 0 1 .32-.215c.125-.049.255-.074.391-.074a1.004 1.004 0 0 1 .922.625.97.97 0 0 1 .078.375z' /></g></svg>") !important;
  border-left-color: black !important;
  border-right-color: black !important;
  border-top-color: black !important;
  border-bottom-color: black !important;
}

.checkBoxDiv .checkBoxClass[aria-disabled="true"] .ui-checkbox__indicator {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' role='presentation' style='background-color: black; padding: 2px;' focusable='false' viewBox='8 8 22.5 22.5'><g><path fill='%23fff' d='M23.5 11.875a.968.968 0 0 1-.289.711l-8.25 8.25c-.192.193-.43.289-.711.289s-.519-.096-.711-.289l-4.75-4.75a.965.965 0 0 1-.289-.711c0-.125.027-.25.082-.375s.129-.234.223-.328a.953.953 0 0 1 .695-.297c.135 0 .266.025.391.074.125.05.231.121.32.215l4.039 4.047 7.539-7.547a.886.886 0 0 1 .32-.215c.125-.049.255-.074.391-.074a1.004 1.004 0 0 1 .922.625.97.97 0 0 1 .078.375z' /></g></svg>") !important;
  border-left-color: rgb(34, 15, 15) !important;
  border-right-color: black !important;
  border-top-color: black !important;
  border-bottom-color: black !important;
  opacity: 0.3;
}
.checkBoxClass .ui-checkbox {
  align-items: center;
}


.minCharMessage, .recordsMessage{
  padding-bottom: 5px;
  font-size: 10px;
}
.recordsMessage{
  padding-bottom: 5px;
  font-size: 12px;
  margin-left: 2px;
}

.filterFooterButtons, .filteredResultsCheckBox{
  margin-top: 15px;
}

.filterIconClass:focus {
  outline: none;
  border: none;
  font-size:20px !important;
  margin-left:  5px;
}
.filterIconClass{
  margin-left: 5px;
  font-size:20px !important;
}
.container-filterSearchIconClass{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: transparent;
  height: inherit;
  width:10%;
  position: relative;
  
}
.disbledClass{
  opacity:0.2;
}
.enabledClass{
  background-color: #FFE600;
}
.filterSearchIconClass{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size:20px;    
  background-color: transparent !important;
  position: absolute;
  top: 50%;
  left: 50%;        
  transform: translate(-50%, -50%);
  padding-left:1.5px;
}
.mtextInputStyles{
  border: 0.175vh solid gray;
  border-radius: 5px;
  background-color: #f6f6fa !important;
  display: flex;
  flex-direction: row;
}
.mtextInputStyles input::placeholder{
  font-size: 11px;
}
.filteredColumnResultsCheckBox .checkBoxDiv .ui-text{
  text-transform: capitalize;
}
.mtextInputStyles .ui-input  .ui-input__input {
  width: 100%;
}
.mtextInputStyles .ui-input {
  width: 90%;
}
.mtextInputStyles .ui-input__icon {
  padding-inline-start: 12px !important;
}
.dialogColumnFiltering{
  max-width: 95% !important;
}

