/* Default styles for larger screens */
@media screen and (max-width: 450px) {
  .responsive-text:before {
    content: "Permissioned \A Role";
    white-space: pre;
  }
  .roleDDWidth {
    width: 100px;
  }
}
@media screen and (min-width: 451px) {
  .responsive-text:before {
    content: "Permissioned Role";
    white-space: nowrap;
  }
  .roleDDWidth {
    width: 130px;
  }
}
.responsive-table th {
  font-weight: normal;
  font-size: 14px;
  text-align: left;
}

.responsive-table th,
.responsive-table td {
  padding: 10px;
  /* border: 1px solid #ccc; */
}

.dialogUserDeleteStyles {
  height: 230px;
}

.pinnedContent > h2 {
  font-size: 17px;
  font-weight: 700 !important;
}

.dialogUserDeleteStyles .ui-loader {
  justify-content: flex-end !important;
}

.dialogUserDeleteStyles .ui-text {
  margin-left: 0;
}

.dialogUserDeleteStyles .ui-dialog__footer button {
  padding: 0px 16px !important;
  background-color: #ffe600 !important;
  color: #2e2e38 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  border: 1px solid #2e2e38 !important;
  transition: all 0.1s !important;
  height: 34px !important;
  font-family: inherit !important;
  text-align: center !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

/* confirmButton Shadow */
.dialogUserDeleteStyles .ui-dialog__footer .li {
  box-shadow: none;
}

.dialogUserDeleteStyles .ui-dialog__footer button:first-child {
  background-color: rgb(255, 255, 255) !important;
}

.dialogUserDeleteStyles .ui-dialog__footer {
  border: none !important;
}

.dialogUserDeleteStyles .ui-dialog__footer > .ui-buttons {
  background-color: white;
}

/* Hide columns on smaller screens */
@media (max-width: 768px) {
  .responsive-table th:nth-child(3),
  .responsive-table td:nth-child(3),
  .responsive-table th:nth-child(4),
  .responsive-table td:nth-child(4),
  .responsive-table th:nth-child(5),
  .responsive-table td:nth-child(5) {
    display: none;
  }

  .responsive-table .standard-width {
    width: 95px; /* Adjust as needed */
    max-width: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 1160px) {
  .responsive-table .more-width {
    width: 95px; /* Adjust as needed */
    max-width: 95px;
    white-space: nowrap;
    overflow: ellipsis;
  }
}

.type-2-table th:nth-child(2),
.type-2-table td:nth-child(2),
.type-2-table th:nth-child(3),
.type-2-table td:nth-child(3),
.type-2-table th:nth-child(4),
.type-2-table td:nth-child(4) {
  display: none;
}

.table-container {
  overflow-x: auto;
}

.responsive-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.responsive-table th,
.responsive-table td {
  padding: 2px 10px;
  border: 0px solid #ccc;
  white-space: nowrap;
  /* Prevent text wrapping */
}
.minimal-width {
  min-width: 35px;
  white-space: nowrap;
}

.standard-width {
  width: 205px; /* Adjust as needed */
  max-width: 205px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: hidden;
}
.more-width {
  width: 235px; /* Adjust as needed */
  max-width: 235px;
  white-space: nowrap;
  overflow: hidden;
}

.less-width {
  width: 90px; /* Adjust as needed */
  min-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.responsive-table tbody tr {
  background-color: #f3f2f1;
}

.responsive-table tbody td {
  padding: 13px 10px;
}

.responsive-table tbody tr:hover {
  background-color: #eee;
}

.responsive-table .fontSize {
  font-size: 14px !important;
}

@media (max-height: 650px) {
  .responsive-table th,
  .responsive-table .fontSize,
  .fontSize .ui-button__content {
    font-size: 12px !important;
  }
}

.fontSize .ui-button__content {
  font-weight: normal !important;
}

.users-avatar__username {
  max-width: 30ch;
}
.users-avatar__username_customusergroup {
  max-width: 50ch;
}
.users-list__tooltip {
  max-width: 200px;
  display: flex;
  align-items: center;
}

@media (max-width: 1100px) {
  .users-list__tooltip {
    max-width: 100px;
  }
}

@media (max-width: 900px) and (min-width: 769px) {
  .users-avatar__username {
    max-width: 18ch;
  }
}

@media (max-width: 500px) {
  .responsive-table .fontSize {
    font-size: 13px !important;
  }

  .fontSize .ui-button__content {
    font-size: 13px !important;
  }

  .users-list__accordion .ui-accordion__content {
    margin-inline-start: 0;
  }

  .users-avatar__username {
    max-width: 20ch;
  }
}

@media (max-width: 400px) {
  .responsive-table .fontSize {
    font-size: 11px !important;
  }

  .fontSize .ui-button__content {
    font-size: 11px !important;
  }

  .AddMemberLeaveCompanyBtn_leaveBtn,
  .AddMemberLeaveCompanyBtn_addBtn {
    font-size: 11px !important;
  }

  .users-avatar {
    width: 25px;
  }
}

.fontSize .ui-dropdown__container {
  margin-left: -14px;
}

.users-list__accordion .ui-accordion__title {
  width: fit-content;
}
.channelmanagement-container .users-list__accordion .ui-accordion__title,
.usermanagement-container .users-list__accordion .ui-accordion__title {
  padding: 0px 20px !important;
}

.username_and_avatar {
  display: flex;
  align-items: center;
}
.username_and_avatar_usergroup {
  display: flex;
  align-items: center;
  height: 15px;
  font-size: 14px;
}

.users-avatar {
  border-radius: 50px;
}

.roles-loader svg {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.roles-loader .ui-loader__indicator {
  height: auto !important;
  width: auto !important;
}

.type-2-table,
.type-2-table .ui-dropdown__selected-items {
  background-color: #fff !important;
}

.type-2-table {
  border-bottom: 0.1px solid #d1d1d1 !important;
}

.type-2-table:hover,
.type-2-table:hover .ui-dropdown__selected-items {
  background-color: #efefef !important;
}

.responsive-table:has(.type-2-table) {
  border-collapse: collapse;
}

.type-2-table .ui-list {
  max-height: 200px !important;
}

.leaveCompany__error {
  position: absolute;
  left: -20px;
  z-index: 5;
  font-size: 18px;
  color: red;
}

.hideTheRow {
  display: none !important;
  position: absolute !important;
}

.linkedIn-profile-icon svg {
  font-size: 16px !important;
  margin-top: 5px !important;
  color: #0077b5 !important;
}

.td-linkedInProfile {
  cursor: pointer;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  transform: translateY(-5%);
}

.content-author-checkbox > div {
  background-color: rgb(91, 95, 199) !important;
  opacity: 0.5;
}

.content-author-checkbox > div::before {
  background-color: rgb(255, 255, 255) !important;
}
.delete-icon {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.content-author-toggle .ui-checkbox {
  color: white !important;
}
