.actionBar {
  background-color: #f6f6fa;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding: 16px; */
  font-size: 18px;
  gap: 10px;
  flex: 1;
  padding: 8px 0px 8px 8px;
}

@media only screen and (min-width: 948px) and (max-width: 1150px) {
  .actionBar {
    gap: 4px;
  }
}

@media only screen and (min-width: 948px) and (max-width: 1048px) {
  .actionBar {
    font-size: 13px;
  }
}

@media only screen and (max-width: 648px) {
  .actionBar {
    gap: 4px;
  }
}

.actionBar div {
  display: flex;
  align-items: center;
}

.actionBar div span {
  margin-left: 3px;
  font-size: 0.8em;
}
.leftAlignActionBar {
  background-color: #f6f6fa;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  font-size: 19px;
  gap: 4%;
}
.leftAlignActionBar div {
  display: flex;
  align-items: center;
}
.leftAlignActionBar div span {
  margin-left: 3px;
  font-size: 0.8em;
}

.leftAlignActionBar svg {
  width: 100%;
}

.action-button-div {
  width: 1.2rem;
}

.mail-cursor {
  cursor: pointer;
}

.more-btn-icons {
  font-size: 14px;
}

.actionIcon-disable {
  color: gray;
  cursor: default;
}

.visibility {
  color: gray;
  cursor: default;
}

.liking {
  width: 2.5rem;
  margin-right: -0.5rem;
}

.liking svg {
  width: 1.5rem;
}

.likeCounter {
  width: 1rem;
  margin-left: auto !important;
  margin-right: 0;
  text-align: left;
}

.actionIcon {
  cursor: pointer;
}

.views-span {
  color: black !important;
  margin-bottom: 2px !important;
  cursor: default;
}

.transitioned {
  animation: rotation 0.25s;
  animation-fill-mode: forwards;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(20deg);
  }
}

.actionButtons {
  background-color: #f6f6fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* padding-right: 14px; */
  position: relative;
}
.moreIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.moreIcons p {
  font-size: 15px;
}
.CTAMenuLayover .ui-loader__indicator {
  position: absolute !important;
  left: 40% !important;
  margin: 0 !important;
}
.loaderLayoverCTA .ui-loader__indicator {
  position: absolute !important;
  left: 40% !important;
  margin: 0 !important;
  top: 15% !important
}
.loaderLayoverCTA > .ui-flex {
  display: contents;
}
.layoverBackground .ui-box {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.ctaMenu .ui-menu__itemcontent {
  width: 100%;  
  color: black !important;
  background: transparent !important;
  opacity: 1 !important;
}

.disable-web-version {
  color: darkgray;
}

.likeLayover {
  background-color: transparent !important;
  box-shadow: none !important;
}
.actionBar .ms-layer {
  display: none;
}
.ui-pin-icon-cursor {
  cursor: pointer !important;
}
.touchMenu {
  width: 88%;
}
#dialogBoxHeaderImg {
  object-fit: cover;
  width: 45px;
  height: 45px;
  border-radius: 4px;
}
.dialogBoxHeader {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.dialogBoxHeaderTitle {
  font-weight: 700;
  font-size: large;
  position: absolute;
  margin-top: 8px;
}
.dialogBoxCloseIcon {
  position: absolute;
  margin-top: 12px;
}
