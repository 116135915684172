.searchFiltersContainer {
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    margin: 0px 20px;
    justify-content: space-between;
}

.deleteStepMessage {
    padding-top: 10px;
    height: 60px;
}

.booleanExpressionTextBox {

    width: 100%;
    padding-right: 3px;
}

.booleanExpressionTextBox .ui-input__input {

    width: 100%
}

.dialogBoxSearchStepDelete .ui-dialog__footer .ui-buttons .ui-flex .ui-button {
    padding: 0px 16px !important;
    background-color: #ffe600;
    color: #2e2e38 !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    border: 1px solid #2e2e38 !important;
    transition: all 0.1s !important;
    height: 34px !important;
    font-family: inherit !important;
    text-align: center !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    opacity: 1 !important;
}

.dialogBoxSearchStepDelete .ui-dialog__footer .ui-buttons .ui-flex button:first-child {
    background-color: rgb(255, 255, 255) !important;
}

.dialogBoxSearchStepDelete .ui-dialog__footer {
    margin-top: 24px;
    margin-bottom: 17px;
    background-color: transparent !important;
    width: 100%;
    opacity: 1 !important;
}

.dialogBoxSearchStepDelete .ui-dialog__footer .ui-buttons {
    opacity: 1 !important;
    background-color: transparent !important;
}


.searchFiltersLabel {
    font-weight: 700;
    margin-right: 24px;
    white-space: nowrap;
}

.searchFilterAllPills {
    display: flex;
    align-items: center;
    gap: 14px;
    flex-wrap: wrap;
}

.searchFilterPills {
    padding: 6px 9px;
    border: 1px solid #E7E7Ea;
    font-size: 14px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 0.5px #E7E7Ea;
    cursor: pointer;
}

.searchParametersMobile {
    display: none;
}

.letGetStartContainer {
    border: 1px solid #C4C4CD;
    padding: 24px;
    border-radius: 4px;
    gap: 20px;
    margin: 24px auto 4px;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-shadow: 0px 0px 10px 1px #c4c4cdd4;
    flex-direction: column;
}

.letGetStartLabel {
    font-weight: 700;
    font-size: 22px;
    font-family: eyInterstate;
}

.letGetStartIcon {
    padding: 20px;
    border-radius: 50%;
    background-color: #ffe600;
    width: 70px;
    height: 70px;
}

img.letGetStartImage {
    width: 32px;
    height: 32px;
    margin: auto;
}

.letGetStartSubText {
    font-size: 14px;
    font-family: eyInterstate;
}

.searchPageContainer .datagrid_menucontainer {
    display: none;
}

.searchPageContainer .datagrid_container input[type="checkbox"] {
    padding: 12px 5px;
}

.searchPageContainer .ms-DetailsHeader-cell:first-child {
    padding-top: 12px;
}

.searchPageContainer .ms-DetailsHeader-cellTitle {
    padding-left: 10px;
}

.searchPageContainer .detailslist_wrapper {
    overflow-y: auto;
    margin-bottom: 120px;
    padding: 0px 20px;
}

.searchPageContainer .detailslist_wrapper.initialGridHeight {
    height: 100%;
    overflow-y: auto;
    margin-bottom: 0px;
}

.searchPageContainerHeight .ms-Viewport {
    min-height: 40vh !important;
    max-height: 40vh !important;
}

.displayCenter {
    display: flex;
    justify-content: center;
    padding-top: 8px;
}

.searchPageFooter {
    position: absolute;
    padding: 0px 20px;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    right: 0px;
}

.footnotesSearchPageGrid {
    margin-bottom: 80px;
}

.footnotesSearchPageGrid .searchPageFooter {
    position: relative;
    padding: 10px 20px 10px 20px;
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    right: 0px;
}

.footnotesSearchPageGrid .letGetStartContainer {
    margin: 24px auto 24px;
}

.searchPageFooterButtons {
    gap: 20px;
    display: flex;
    gap: 15px;
    justify-content: flex-end;
}

.searchPageFooterButtons .excuteButton {
    background-color: #2e2e2e;
    color: #fff;
    width: 8rem;
    height: 2.5rem;
}

.booleanParametersFieldwithIcon {
    display: flex;
    gap: 5px;
}

.booleanParametersFieldwithIcon svg {
    margin-bottom: 4px;
    cursor: pointer;
}

.searchPageFooterButtons .viewSearchButton {
    background-color: #e1e1e6;
    color: #000000;
    width: 8rem;
    height: 2.5rem;
}

.searchPageFooterButtons .excuteButton:hover {
    background-color: #747480;
    color: #fff;
}

.searchPageFooterButtons .excuteButton.viewReport {
    background-color: #ffe600;
    color: #000000;
}

.searchPageFooterButtons .excuteButton:disabled {
    background-color: #747480;
    color: #fff;
}

.booleanParametersContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.booleanParametersFieldsLeft {
    width: 60%;
    padding-right: 10px;
}

.booleanParametersFieldwithIcon {
    display: flex;
    gap: 5px;
}

.booleanParametersFieldwithIcon svg {
    margin-bottom: 4px;
    cursor: pointer;
}

.booleanLabel {
    font-family: Eyinterstate;
    color: #9897a6;
}

.booleanParametersFieldsRight {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.booleanParametersButtonFields {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.booleanParametersFieldsRight .formLabelStyles {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.stepsAppliedFeilds {
    width: -webkit-fill-available;
}

.totalSearchResults {
    width: -webkit-fill-available;
}

.booleanParametersContainer .ui-input__input,
.booleanParametersContainer .ui-input__input:focus {
    border-radius: 4px;
    background-color: #e7e7ea !important;
    border: none;
}

.booleanParameterNote {
    font-size: 12px;
    font-weight: 400;
    font-family: Eyinterstate;
    color: black;
    margin-top: 4px;
    opacity: 0.7;
}

.booleanParameterErrorNote {
    font-size: 12px;
    font-weight: 400;
    font-family: Eyinterstate;
    color: rgb(238, 12, 12);
    margin-top: 4px;
    opacity: 0.7;
}

.landingPagePanel .panelViewPopupHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.columnContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dragIcon {
    margin-bottom: 6px;
}

.searchPageDetailsList .ms-DetailsRow:hover {
    background: none;
}

.searchPageDetailsList .ms-DetailsRow {
    background: none;
}

.searchPageDetailsList .ms-DetailsHeader-cell:hover {
    background: none;
}

.searchPageDetailsList .ms-DetailsRow-fields {
    background: none;
}

.searchPageDetailsList .formCheckboxStyles .ui-box {
    opacity: 1;
}

.searchPageDetailsList .formCheckboxStyles .ui-box {
    opacity: 1;
}

.searchFormCheckbox .ui-checkbox__indicator {
    border-radius: 0px;
    height: 0.8rem;
    width: 0.8rem;
}

.paramActionIcons {
    display: flex;
    gap: 10px;
    padding: 5px;
}

.paramActionEdit {
    cursor: pointer;
}

.paramActionDeleteNone {
    cursor: not-allowed;
    opacity: 0.5;
}

.paramActionDelete {
    cursor: pointer;
}

.paramActionDeleteNone {
    cursor: not-allowed;
    opacity: 0.5;
}

.columnCheckbox {
    margin-top: -5px;
    cursor: pointer;
}

.columnContainer {
    margin-top: -2px;
}

.searchParamType {
    text-decoration: none;
    font-weight: 600;
    color: #0d6efd;
    cursor: pointer;
}

.descriptionParam {
    font-weight: 700;
}

.searchCriteriaContent {
    font-weight: 500;
}

.searchCriteriaPTag::first-line {
    font-weight: 500;
    line-height: 1.5;
}

.searchCriteriaPTag {
    line-height: 2;
}

.searchPageDetailsList .ms-DetailsRow-cell {
    white-space: pre-wrap;
    overflow: visible;
}

.panelControls .formLabelStyles {
    font-weight: 600;
    font-size: 14px;
}

.fromToDatePickersContainer {
    display: flex;
    gap: 10px;
}

.fromToDatePickersContainer .datePickerStyles {
    width: 100%;
}

.fromToDatePickerStart,
.fromToDatePickerEnd {
    width: 100%;
}

.fromToDatePickersContainer .datePlaceHolderClass {
    display: none;
}

.footnotesSearchPageGrid .fui-Accordion {
    margin: 10px 20px;
}

.footnotesFooter {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
    width: 100%;
    border-top: 1px solid #e1dfdd;
    margin-top: 24px;
    position: relative;
    bottom: 0;
}

.exportToExcelBtn .excuteButton,
#exportId:hover {
    height: 100%;
    width: 100px;
    padding: 10px;
    cursor: pointer;
    background-color: #ffe600;
    color: #000000;
}

.footnotesSearchPageGrid .fui-Accordion .ms-Viewport {
    margin: 0px 20px 20px;
}

.footnotesSearchPageGrid .fui-Accordion .searchPageFooter {
    padding: 20px 20px 20px;
    width: 100%;
}

.footnotesSearchPageGrid .fui-AccordionItem {
    border: 1px solid #e1dfdd;
    border-radius: 4px;
    margin-bottom: 14px;
}

.footnotesSearchPageGrid .footnotesAccordionHeaders {
    padding: 0px 10px;
    font-weight: 600;
}

.footnotesAccordionHeaders .fui-AccordionHeader__button {
    gap: 6px;
}

.footnotesSearchPageGrid .clearsSelection {
    text-align: end;
    margin-right: 21px;
    color: #0d6efd;
    font-size: 14px;
    font-weight: 600;
}

.clearsSelection.disabled {
    opacity: 0.7;
    color: #bfbfbf;
}

.footnotesSearchPageGrid .clearsSelection:hover {
    cursor: pointer;
}

.footnotesSearchPageGrid .clearsSelectionText {
    margin-left: 4px;
}

.footnotesSearchPageGrid .footnotesAccordionBody {
    overflow-y: auto;
}

.detailslist_wrapper .searchPageDetailsList .ms-DetailsRow-cell:hover {
    cursor: move !important;
}

.detailslist_wrapper .ui-checkbox {
    cursor: pointer !important;
}

.footnotesSearchPageGrid .footnotesAccordionBody .ms-Viewport {
    overflow-y: auto;
    overflow-x: visible;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.footnotesSearchPageGrid .footnotesAccordionBody .ms-Viewport::-webkit-scrollbar {
    width: 8px;
    height: 0;
}
.footnotesSearchPageGrid .footnotesAccordionBody .ms-Viewport::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}
.footnotesSearchPageGrid .footnotesAccordionBody .ms-Viewport::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.footnotesFooter.exportBtnBottom {
    position: absolute;
}

.reportLoader {
    min-height: 12rem !important;
    margin: auto;
    width: 0%;
}

.searchFilterPillsContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 16px;
}

.uploadReportClass {
    font-size: 14px;
    font-weight: 600;
    color: #0D6EFD;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}

.loaderLayoverUploadReport {
    position: absolute !important;
    left: 50% !important;
    margin: 0 !important;
    bottom: 50% !important;
    z-index: 111;
}

.uploadReportLayover {
    pointer-events: none;
}

.uploadReportDropzone {
    text-align: center;
    padding: 30px;
    border-style: dashed;
    border-width: 2px;
    border-color: #eeeeee;
    background-color: #fafafc;
    margin-bottom: 20px;
}

.uploadReportDropzoneDisaled {
    text-align: center;
    padding: 30px;
    border-style: dashed;
    border-width: 2px;
    border-color: #eeeeee;
    background-color: #fafafc;
    margin-bottom: 20px;
    color: grey;
    cursor: initial;
}

.browse {
    color: rgb(40, 96, 201);
    font-weight: 600;
    cursor: pointer;
}

.browseDisabled {
    color: grey;
    font-weight: 600;
}

.uploadButton {
    font-size: 30px;
    cursor: pointer;
}

.dragDropContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    margin: 20px;
}

.errorMessage {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    gap: 5px;
}

.errorMessage>svg {
    color: rgb(196, 49, 75);
    margin-top: 3px;
}

.errorMessage>span {
    font-size: 16px;
}

.iconStyle {
    font-size: 17px;
    color: rgb(196, 49, 75);
}

.footerUploadReport {
    margin: 15px 0px 0px 0px;
}

.dropzoneBody {
    text-align: center;
    padding: 15px;
    border-style: dashed;
    border-width: 2px;
    border-color: #eeeeee;
    background-color: #fafafc;
    margin-bottom: 20px;
}

.dropzoneBodyDisabled {
    text-align: center;
    padding: 15px;
    border-style: dashed;
    border-width: 2px;
    border-color: #eeeeee;
    background-color: #fafafc;
    margin-bottom: 20px;
    color: grey;
    cursor: initial;
}

.dropzoneContentBody {
    margin-top: 15px;
    display: "flex";
    flex-direction: "column";
    gap: "15px"
}

.attachmentStyles {
    background: transparent !important;
}

@media screen and (max-width: 724px) {
    .booleanParametersContainer {
        display: block;
    }

    .searchParametersMobile {
        display: block;
        margin: 0px 20px;
    }

    .searchFiltersContainer {
        display: none;
    }

    .searchParametersMobile .dropdownStyles {
        border: 1px solid #C4C4CD;
        border-radius: 4px;
    }

    .searchParametersMobile .dropdownStyles .ui-dropdown__selected-items {
        background-color: #f6f6fa;
        width: 100%;
        border-radius: 4px;
    }

    .booleanParametersFieldsLeft,
    .booleanParametersFieldsRight {
        width: 100%;
    }

    .searchPageFooterButtons .excuteButton {
        background-color: #2e2e2e;
        color: #fff;
        width: 140px;
        height: 2.5rem;
    }

    .searchPageFooterButtons .viewSearchButton {
        background-color: #e1e1e6;
        color: #000000;
        width: 100%;
        height: 2.5rem;
    }

    .letGetStartContainer {
        border: 1px solid #C4C4CD;
        padding: 24px;
        border-radius: 4px;
        position: sticky;
        left: 8%;
        gap: 20px;
        margin: 24px auto;
        max-width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        box-shadow: 0px 0px 10px 1px #c4c4cdd4;
        flex-direction: column;
    }

    .searchPageContainer .detailslist_wrapper {
        overflow-y: auto;
        margin-bottom: 250px;
    }

    .booleanParametersContainer .formLabelStyles {
        color: #2e2e2e !important;
        width: auto !important;
    }

    .footnotesSearchPageGrid .searchPageDetailsList {
        overflow: auto;
    }

    .searchParametersMobile.parent .dropdownStyles .ui-dropdown__items-list .ui-dropdown__item {
        pointer-events: none !important;
        opacity: 0.5 !important;
    }

    .searchParametersMobile.parent .dropdownStyles .ui-dropdown__items-list .ui-dropdown__item:first-child {
        pointer-events: auto !important;
        opacity: 1 !important;
    }

    .searchParametersMobile.child .dropdownStyles .ui-dropdown__items-list .ui-dropdown__item {
        pointer-events: auto !important;
        opacity: 1 !important;
    }

    .searchParametersMobile.child .dropdownStyles .ui-dropdown__items-list .ui-dropdown__item:first-child {
        pointer-events: none !important;
        opacity: 0.5 !important;
    }

    .footnotesFooter {
        position: absolute;
    }
}

.custom_report_loader {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(204, 201, 201, 0.5);
    /* Light border for contrast */
    border-top: 5px solid #3498db;
    /* Bright color for the animated part */
    border-radius: 50%;
    /* Makes it circular */
    animation: custom_report_spin 1s linear infinite;
    /* Smooth rotation */
    margin: auto;
    /* Center it */
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@keyframes custom_report_spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


#PieBreakDown_single_org {
    width: 100%;
}

.plot-container {
    font-weight: normal;
}