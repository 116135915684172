.FooterTechButtons {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.disabled {
    background-color: #ebebeb;
    cursor: default !important;
}

.form_footer .disabled,
.form_footer .disabled:hover {
    background-color: #ebebeb !important;
    opacity: 1 !important;
    border: 0 !important;
    color: rgb(210, 208, 206) !important;
    cursor: default;
}

.FooterTechButtons button {

    /* width: 5.5rem; */
    cursor: pointer;
    border-radius: 4px;
    /* height: 2rem; */

}

.btn_save {
    margin-left: 1rem;
    font-weight: 700;
    border: 1px solid #2e2e38 !important;
}

.btn_save,
.btn_save:hover {
    background-color: #FFE600 !important;
}

.btn_cancel {
    /* background:  #ebebeb; */
    background-color: white;
    border: 1px solid #2e2e38;
    font-weight: 700;
}

.btn_cancel:hover {
    /* background:  #ebebeb!important; */
    background-color: white;
    border: 1px solid #2e2e38;
}
