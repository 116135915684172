.common_container {
  padding: 0px;
  margin: 0;
}

.showNotifications {
  margin-top: 7px;
}

/* .large {
  width: 77%;
}

.small {
  width: 96%;
} */

.common_container_header {
  font-size: 20px;
  font-weight: 600;
  /* border-bottom: 1px solid lightgrey; */
  /* background-color: #fff; */
  position: sticky;
  top: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-left: 2.75%;
  z-index: 1;
  height: auto;
  min-height: 50px;
  border-bottom: 1px solid lightgrey;
  background-color: #fff;
}

.showMenu {
  border-bottom: 1px solid lightgrey;
  background-color: #fff;
}

.showSubMenuToaster {
  /* top: 15.7vh; */
  position: sticky;
  z-index: 12;
}

.common_container_header p {
  /* margin: 20px 40px 0 40px; */
  font-size: 24px;
}

.common_container_subtitle {
  border-bottom: 1px solid lightgrey;
  display: flex;
  justify-content: space-between;
  margin: 0px 40px;
  text-transform: capitalize;
  padding-bottom: 6px;
  height: 55px;
  gap: 8px;
}

/* .common_title{
  display: inline-block;
  text-transform: capitalize;
} */

.common_container_leftMenu {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  gap: 7px;
}

.common_container_leftMenu p {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 600;
  padding-left: 5px;
  position: relative;
  top: 10px;
}

.common_container_leftMenu svg {
  position: relative;
  top: 10px;
  cursor: pointer;
  font-size: 14px !important;
}
.shortcutIconDiv {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  margin-left: 7px;
  position: relative;
  top: 2px;
  cursor: pointer;
  font-size: 14px !important;
}
.shortcutIconDiv active {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  margin-left: 7px;
  position: relative;
  top: 2px;
  cursor: pointer;
  font-size: 14px !important;
  background-color: black;
}
.common_container_libraryIcon {
  cursor: pointer;
}

.libraryIcon {
  cursor: pointer;
}

.common_container_rightMenu {
  font-weight: 600;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding-bottom: 5px;
  width: 100%;
}

.common_container_rightMenu p {
  /* margin-top: 25px; */
  margin-bottom: 0 !important;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.common_container_rightMenu p .badge {
  margin-top: 4px;
}

.common_container_rightMenu > .divLink > a {
  text-decoration: none;
  color: #000;
  padding: 3px 8px;
  border-color: #000;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  margin-left: 15px;
}

.common_container_rightMenu > .globalDivLink > a {
  text-decoration: none;
  color: #fff;
  background-color: #000;
  padding: 3px 8px;
  border-color: #000;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  margin-left: 15px;
  font-weight: 500;
}

.globalDivLink {
  min-width: max-content;
}

.common_container_cscontent {
  text-align: center;
  /* padding-top: 15%; */
}

.common_container_cscontent p {
  margin-bottom: 0px;
}

.common_container_cscontentHeader {
  font-weight: 700;
  font-size: 14px;
}

.common_container_cscontentSubtext {
  font-size: 12px;
}

/* @media screen and (max-width: 900px) {
  .large {
    width: 65%;
  }
} */

.inactive {
  pointer-events: none;
  color: grey !important;
  border-color: grey !important;
}

.submenuHeder {
  padding-bottom: 10px;
}

@media only screen and (min-width: 390px) and (max-width: 680px) {
  .common_container_rightMenu p {
    font-size: 16px;
  }

  .common_container_rightMenu {
    width: 80%;
  }

  .common_container_leftMenu {
    padding-bottom: 5px;
  }

  .common_container_subtitle {
    margin: 0px 1.4rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 389px) {
  .common_container_rightMenu p {
    font-size: 16px;
  }

  /* .common_container_leftMenu {
    padding-bottom: 11px;
  } */

  .common_container_subtitle {
    /* flex-wrap: wrap; */
    margin: 0px 20px;
    gap: 10px;
  }

  .common_container_rightMenu > .divLink > a {
    width: 40%;
  }

  .common_container_rightMenu p {
    display: inline-block;
  }

  /* .divTxtSubmenu {
    flex: 1;
  } */
}

.divLink {
  padding-bottom: 4px;
  min-width: max-content;
}

.commonTitleEllipses {
  word-break: break-word;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.divTxtSubmenu {
  max-width: 80%;
}

.addRemoveShortcutIcon {
  width: 27px;
  padding-bottom: 5px;
  margin-left: 10px;
}
.addTopBarRemoveShortcutIcon {
  width: 20px;
  
}

.contactus-close1 {
  float: right;
  width: 16px !important;
  padding-top: 10px;
  cursor: pointer;
}
#\/channelcreation.headerWebVersion .common_container_subtitle.freezeHeader {
  position: sticky;
  top: 90px;
  background-color: #fff;
  z-index: 3;
  padding: 0px;
  margin: 0px !important;
  padding-left: 20px;
}
#\/channelcreation.headerTeamsVersion .common_container_subtitle.freezeHeader {
  position: sticky;
  top: 40px;
  background-color: #fff;
  z-index: 3;
  padding: 0px;
  margin: 0px !important;
  padding-left: 20px;
}
#\/channelcreation .common_container_subtitle.freezeHeader .common_container_rightMenu {
  margin-top: 15px;
}
@media (max-width: 710px) {
  #\/channelcreation .common_container_subtitle.freezeHeader {
      padding-left: 20px;
  }
}
