.toasterNotificationWrapper1 {
  /* width: 95%; */
  font-size: 14px !important;
  /* margin-top: 4px; */
  /* padding-bottom: 12px; */
}

.toasterNotification-MarginTop {
  margin-top: 35px;
}

.toasterNotificationWrapper1 svg {
  margin-right: 6px !important;
  font-size: larger !important;
}

.ui-alert__content .hk {
  font-weight: 600;
}
.seeDetailsButton {
  width: 77px;
  height: 28px;
  background-color: white;
  color: #2e2e38;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  border: 1px solid #e3dcdc;
  transition: all 0.1s;
  margin: 0 0.3rem;
  border-radius: 4px;
}
.seeDetails {
  margin-bottom: 4px;
}
.validationContent {
  font-size: 14px;
}

@media only screen and (min-width: 320px) and (max-width: 389px) {
  .toasterNotificationWrapper1 {
    word-break: break-all;
    width: 100%;
  }

  .toasterNotificationWrapper1 .ui-alert .ui-alert__content div {
    /* display: block !important; */
    display: flex;
    align-items: center;
  }

  .toasterNotificationWrapper1 .ui-alert .ui-alert__content div > svg {
    /* float: left; */
    margin-right: 10px; /* This adds some space between the svg and the text */
  }

  .showToaster {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    top: 3.7%;
  }
}

@media only screen and (min-width: 390px) and (max-width: 768px) {
  .toasterNotificationWrapper1 {
    word-break: break-all;
    width: 100%;
  }

  .toasterNotificationWrapper1 .ui-alert .ui-alert__content div {
    display: block !important;
  }

  .toasterNotificationWrapper1 .ui-alert .ui-alert__content div > svg {
    float: left;
  }

  .showToaster {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
