.commonTitleEllipses_internalApp {
     word-break: break-word;
     width: 100%;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     -webkit-box-orient: vertical;
}

.showEllipses_internalApp {
     overflow: hidden;
     text-overflow: ellipsis;
}
.PowerK-close-btn svg{
     font-size: 24px !important;
     margin-left: 20px;
     padding: 3px;
}

.divTxtSubmenu_internalApp {
     max-width: 100%;
}

.common_container_subtitle_internalApp {
     border-bottom: none;
     display: flex;
     justify-content: space-between;
     margin: 0px 20px;
     /* text-transform: capitalize; */
     padding-bottom: 6px;
     height: 55px;
     gap: 8px;
}

.common_container_rightMenu_internalApp {
     font-weight: 600;
     margin-bottom: 0px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin-top: 25px;
     padding-bottom: 5px;
     width: 100%;
}

.common_container_rightMenu_internalApp p {
     /* margin-top: 25px; */
     margin-bottom: 0 !important;
     font-size: 25px;
     display: flex;
     justify-content: center;
     align-items: center;
     gap: 2px;
}

/* .dataBaseLink_internalApp {
     position: absolute;
     right: 5%;
     display: flex;
     justify-content: flex-end;
     align-items: center;
} */
.common_container_rightMenu_internalApp .dataBaseLink_internalApp svg{
     padding-right: 4px;
     font-size: 18px;
     margin-top: 2px;
 }
  .common_container_rightMenu_internalApp .dataBaseLink_internalApp{
     display: flex;
     justify-content: flex-end;
     align-items: center;
     
 }
 .selection-enable{
     color: #0D6EFD;
     cursor: pointer;
     
 }
 .selection-disable{
     color:  #bfbfbf;
     cursor: pointer;
     pointer-events: none;
 }
 .common_container_rightMenu_internalApp .dataBaseLink_internalApp .clearSelectionLink_internalApp
 {
     font-weight: 600;
     font-size: 16px;
     padding-left: 4px;
     margin-top: 3px;
 }
 .common_container_rightMenu_internalApp .dataBaseLink_internalApp .clearSelectionLink_internalApp svg{
     padding-left: 4px;
     font-size: 24px !important;
     margin-top: -2px
 }
.databaseLinkStyleDetails_internalApp {
     text-decoration: none;
     display: flex;
     align-items: center;
     font-weight: 600;
     font-size: 16px;
}

.hide_cover_internalApp {
     display: none;
}

.internalApp_container {
     position: relative;
     width: 100%;
     height: inherit;
}
.internalApp_container .featuresAlert{
     margin: 10px 0px 0px;
     padding-bottom: 0px;
}
.internalApp_cover {
     height: 100%;
     background-color: rgba(255, 255, 255, 0.5);
     position: absolute;
     width: 100%;
     z-index: 8;
}
.container_internalApps{
     display: flex;
     margin-bottom: 15px;
}
.featuresAlert .ui-alert{
     padding: 0;
     margin: 5px 20px 0px;
     background-color: #fff;
     border-radius: 5px;
     border: 1px solid #d7d7dc;
     box-shadow: 1px 2px 7px -1px #d7d7dc;
     display: flex;
     flex-direction: row;
     align-items: center;
}
.featuresAlert .ui-alert button{
     margin-right: 15px;
}
.featuresAlertContainer{
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin: 12px 14px;
}
.featuresAlertText{
     font-size: 12px;
     font-weight: 500;
     color: #747480;
}
.featuresAlertRight .featuresAlertLink{
     text-decoration: none;
     font-weight: 600;
}
.featuresAlertLeft {
     display: flex;
     align-items: center;
}
.featuresAlertLeft .searchFilterPills{
     border-radius: 20px;
     outline: 2px solid  #d7d7dc;
}
.featuresAlertLeft .ui-button__content{
     font-size: 14px;
     font-weight: 600;
}
.newUpcomingFeaturesDialoag {
     max-height: 94vh !important;
}
.featuresAlertLeft .featuresAlertText{
     margin-top: 5px;
}
.dynamicHeight{
     height: 100%;
}
.dynamicGridColumns{
     grid-template-columns: 0% 100% !important;
}
.maHeight{
     max-height: calc(100vh - 100px);
}
@media only screen and (min-width: 320px) and (max-width: 720px)
{
     .common_container_rightMenu_internalApp {
          font-weight: 600;
          margin-bottom: 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 24px;
          padding-bottom: 5px;
          width: 100%;
          gap: 4px;
     }
     .common_container_rightMenu_internalApp .showEllipses_internalApp {
          overflow: initial;
          font-size: 16px;
          white-space: nowrap;
          text-overflow: unset;
          font-weight: 700;
     }
     .common_container_subtitle_internalApp {
          border-bottom: none;
          display: flex;
          justify-content: space-between;
          margin: 0px 20px;
          text-transform: capitalize;
          padding-bottom: 6px;
          gap: 8px
     }
     .common_container_rightMenu_internalApp .dataBaseLink_internalApp {
          display: flex;
          justify-content: end;
          align-items: center;
          width: 100%;
          margin: 10px 0px;
          gap: 4px;
     }
     .common_container_rightMenu_internalApp .dataBaseLink_internalApp .clearSelectionLink_internalApp{
          padding-left: 0px;
          font-size: 14px;
          white-space: nowrap;
     }
     .featuresAlertContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 12px 0px 15px 5px;
      }
      .featuresAlertLeft {
          display: flex;
          align-items: center;
          gap: 4px;
      }
      .featuresAlert .ui-alert button {
          margin-right: 0px;
          margin-top: 0px;
          padding: 0px 8px;
      }
      .featuresAlertContainer .featuresAlertRight {
          font-size: 12px;
          white-space: nowrap;
      }
      .newUpcomingFeaturesDialoag, .dialogBoxSearchStepDelete {
          width: 96% !important;
      }
      .panelViewPopup{
          width: 100% !important;
      }
      .searchPageFooter {
          position: absolute;
          padding: 10px 20px 10px 20px;
          background-color: #fff;
          bottom: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          right: 0px;
          gap: 10px;
      }
      .featuresAlert .ui-alert{
          margin: 4px;
      }
      .PowerK-close-btn svg{
          margin-left: 2px;
     }
     .databaseLinkStyleDetails_internalApp {
          font-size: 14px;
     }
     .common_container_rightMenu_internalApp .dataBaseLink_internalApp svg{
          margin-left: 0px;
     }
     .maHeight{
          max-height: initial !important;
     }
     .dynamicHeight{
          height: calc(100vh - 95px);
     }
}
