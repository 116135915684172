.searchWrapper.search-wrapper {
  width: 100%;
  background-color: #f6f6fa;
  border: 0;

  padding: 0.5rem;
}

.multiselectdivlabel {
  position: relative;
  bottom: 6px;
}

.multiselectClass {
  border-bottom: 0.03vh solid #2e2e38;
}

.multiselect_error {
  border-bottom: 0.03vh solid;
  border-bottom-color: rgb(196, 49, 75);
}
.icon_down_dir:before {
  content: "" !important;
  cursor: pointer;
}

.search-wrapper input {
  width: 100%;
}

/* .multiSelectContainer li:hover {
  background: #818384;
  color: #fff;
  cursor: pointer;
} */
.optionListContainer .optionContainer .highlightOption{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: white;
  color: black;
}
.optionListContainer .optionContainer .option{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}
.displayBlock{
  height: 20em;
}
.multiSelectContainer li:hover {
  background: white !important;
  color: black !important;
  cursor: pointer;
}
.tileAction input{
  cursor: default !important;
}
.optionContainer .option .checkbox{
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.chip {
  background: black;
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
}
.chip > i {
  margin-left: 5px;
  margin-top: 2px;
  cursor: pointer;
}
.disableSelectOptions .optionContainer li:not(:first-child){
  opacity: .5;
  pointer-events: none;
}