.filterArea {
     font-size: large;
     padding-left: inherit;
}

.hide_cover_powerKDB {
     display: none;
}

.powerKDatabase_cover {
     height: 100%;
     background-color: rgba(255, 255, 255, 0.5);
     position: absolute;
     width: 100%;
     z-index: 8;
}

.powerKDatabase_container {
     position: relative;
     width: 100%;
}

.powerKDatabase_container .detailslist_wrapper {
     max-height: 70vh;
}

.powerKDatabase_container .ms-DetailsRow {
     pointer-events: none;
}
   
.powerKDatabase_container .ms-DetailsRow-cellCheck {
     pointer-events: all;
}

.status_pill {
     border-radius: 50px;
     display: flex;
     text-overflow: ellipsis;
     overflow: hidden;
     height: 1.5rem;
     width: 6rem;
     align-items: center;
     justify-content: center;
}

.status_pill_new:hover {
     background-color: #9897A6;
}

.status_pill_new {
     border: 2px solid #F3F3F5;
     border-color: #2E2E38;
}

.status_pill_in_progress:hover {
     background-color: #D2E1FA;
}

.status_pill_in_progress {
     border: 2px solid #1275C1;
}

.status_pill_processed:hover {
     background-color: #F2D9E8;
}

.status_pill_processed {
     border: 2px solid #B14891;
}

.status_pill_prompted:hover {
     background-color: #9c82d429;
 }
 
 .status_pill_prompted {
     border: 2px solid #724bc3;
 }

.status_pill_published:hover {
     background-color: #E7FCE8;
}

.status_pill_published {
     border: 2px solid #1F893F;
}

.status_pill_completed:hover {
     background-color: #E7FCE8;
}

.status_pill_completed {
     border: 2px solid #1F893F;
}

.status_pill_skipped:hover {
     background-color: #FCD9BC;
}

.status_pill_skipped {
     border: 2px solid #B26100;
}

.status_pill_failed:hover {
     background-color: #FCD7CF;
}

.status_pill_failed {
     border: 2px solid #EA011D;
}

.commonTitleEllipses_powerKDB {
     word-break: break-word;
     width: 100%;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     -webkit-box-orient: vertical;
}

.showEllipses_powerKDB {
     overflow: hidden;
     text-overflow: ellipsis;
}

.divTxtSubmenu_powerKDB {
     max-width: 100%;
}

.common_container_subtitle_powerKDB {
     border-bottom: none;
     display: flex;
     justify-content: space-between;
     margin: 0px 20px;
     /* text-transform: capitalize; */
     padding-bottom: 6px;
     height: 55px;
     gap: 8px;
}

.common_container_rightMenu_powerKDB {
     font-weight: 600;
     margin-bottom: 0px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin-top: 25px;
     padding-bottom: 5px;
     width: 100%;
}

.common_container_rightMenu_powerKDB p {
     /* margin-top: 25px; */
     margin-bottom: 0 !important;
     font-size: 25px;
     display: flex;
     justify-content: center;
     align-items: center;
     gap: 2px;
}


.backToLibraryLink_powerKDB .linkStyleDetails_powerKDB {
     color: #0d6efd;
     font-weight: 600;
     font-size: 16px;
     text-decoration: none;
     text-transform: none;
}

.backToLibraryLink_powerKDB .linkStyleDetails_powerKDB svg {
     margin-bottom: 1px;
}

.backToLibraryLink_powerKDB .linkStyleDetails_powerKDB svg {
     margin-bottom: 1px;
}

.container_powerkDataSource {
     display: flex;
     margin-bottom: 15px;
}
