.company-dropdown {
  position: relative;
  max-width: 300px;
}

.cdropdown-header {
  padding: 8px;
  background-color: #f2f2f2;
  /* border: 1px solid #ddd; */
  /* border-radius: 4px; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: end;
  min-width: 200px;
  gap: 2px;
}

.cdropdown-header span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 260px;
  font-weight: 700;
}

.cdropdown-icon {
  font-size: 1.2rem;
  color: #333;
  /* Black color */
}

.cdropdown-options {
  /* position: absolute;
  top: calc(100% + 36px);
  left: 0; */
  width: 100%;
  max-height: 100px;
  /* Adjust the height as needed */
  overflow-y: auto;
  /* Enable vertical scrolling */
  background-color: #fff;
  /* border: 1px solid #ddd; */
  /* border-radius: 4px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.cdropdown-option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #333;
  /* Black color */
}

.cdropdown-option:hover {
  background-color: #eaeaea;
  /* Light grey */
}

.cdropdown-option.selected {
  background-color: #edebe9;
  /* Medium grey */
  color: #000;
  /* White color */
}

.csearch-container {
  position: relative;
  height: 30px;
  margin-top: 2px;
  left: 0;
  width: 100%;
}

.csearch-container input {
  width: 100%;
  padding: 8px 30px 8px 10px;
  border: 1px solid #ddd;
  /* border-radius: 4px; */
  line-height: 10px;
}

.cclear-search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
  /* Grey color */
}

.cdropdown-loader .ui-loader__indicator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cdropdown-loader svg {
  height: 15px;
  width: 15px;
}

.csearch-container input[type="text"]:focus {
  outline: none !important;
  border-color: #ddd;
  box-shadow: 0 0 10px #ddd;
}

.cHintMsg {
  padding: 8px;
  background: #fff;
  border: 1px solid #ddd;
  /* border-radius: 4px;
  margin-bottom: 5px; */
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .csearch-container input[type="text"] {
    font-size: 14px;
  }
  /* .company-dropdown {
      max-width: 150px;
  } */
  .cdropdown-header span {
    max-width: 125px;
  }
  .cdropdown-header {
    min-width: 150px;
    gap: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .subscription_image_wrapper {
    margin-right: 2% !important;
  }
}

/* .subscription_image {
  margin-right: 5%;
} */

.subscription_image_wrapper {
  margin-right: 3%;
}

.arrow-list {
  list-style: none;
  /* Remove default bullets */
  padding-left: 0;
  /* Remove default padding */
  margin-bottom: 0.5rem !important;
}

.arrow-list li {
  position: relative;
  /* Position relative to add custom bullet */
  padding-left: 15px;
  /* Space for the arrow */
  margin-right: -2px;
}

.arrow-list li::before {
  content: "\25B8";
  /* Unicode black right-pointing small triangle character */
  position: absolute;
  /* Position it absolutely */
  left: 0;
  /* Align it to the left */
  color: white;
  /* Triangle color */
}

.status-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #09ea09;
  border-radius: 50%;
  margin-right: 5px;
}

.separator {
  border: 0;
  height: 1px;
  background: #ccc;
  margin: 0px 0;
}

.cdropdown-options-container {
  display: flex;
  flex-direction: column;
  max-height: 300px; /* Adjust as needed */
  overflow: hidden; /* Prevent the entire container from scrolling */
  border: 1px solid #ddd;
}

.cdropdown-pinned-options {
  background: #fff; /* Ensure background matches dropdown */
  z-index: 1; /* Ensure it stays above the scrollable content */
}
