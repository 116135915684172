.common_container_header {
  font-size: 20px;
  font-weight: 600;  
  position: sticky;
  top: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-left: 2.75%;
  z-index: 1;
  height: auto;
  min-height: 50px;
  border-bottom: 1px solid lightgrey;
  background-color: #fff;
}

.common_container_header p {
  /* margin: 20px 40px 0 40px; */
  font-size: 24px;
}

.customUserGroupCreationHeader {
  background-color: #fff;
  border-bottom: 1px solid #d3d3d3;
}

.customusergroupnote {
  padding: 20px 20px 10px 40px;
}

.customusergroupclose {
  float: right !important;
  width: 16px !important;
  padding-top: 10px;
  cursor: pointer;
}

.usergroupbody {
  flex-direction: column;
  margin: 15px 40px 10px 40px ;
  width: 60%;
  margin-bottom: 100px;
}

.row_wrapper {
  padding-bottom: 20px;
}

.row_wrapper .ui-input__input {
  padding-right: 0.75rem;
}

.row_wrapper .ui-input__icon {
  display: none;
}

.add-users__people-picker {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px; 
}

.add-users__people-picker .formPeoplePickerStyles {
  flex: 1 1;
}
/* .username_and_avatar{
    height: 15px;
    font-size: 14px;
} */

.formFooter .disabled,
.formFooter .disabled:hover {
  background-color: #ebebeb !important;
  opacity: 1 !important;
  border: 0 !important;
  color: rgb(210, 208, 206) !important;
  cursor: default;
}


@media (max-width: 400px) {
  .add-users__people-picker {
    flex-direction: column;
    gap: 4px;
    width: 100%;
    align-items: normal;
  }

  .add-users__people-picker .teamsbtn button {
    width: 100% !important;
  }

}

.add-users__people-picker .formPeoplePickerStyles input {
  height: 35px;
}

.usergroup-toggleContainer {
  position: relative !important;
  /* bottom: 500px;  
    width: 40%;     */
  display: flex !important;
  color: black;
  bottom: 45px;
  margin-left: 40px;
  margin-top: 90px;
  margin-bottom: 30px;
}

.users-results-group {
  height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 5px;
}

.users-already-exist {
  background: #efefef;
  display: flex;
  justify-content: space-between;
  padding: 3px;
  align-items: center;
}

.users-already-exist svg {
  margin-right: 20px;
}

@media only screen and (min-width: 320px) and (max-width: 800px) {
  .section_wrapper {
    display: block;
  }

  .formDropdownStyles {
    padding-bottom: 1rem;
  }

  .usergroupbody {
    width: 90%;
    margin: 2rem 2rem 2rem 1rem;
  }

  .customUserGroupCreationHeader {
    top: 39px;
    padding: 0 18px;
    min-height: 3.7vh;
  }

  .section_wrapper>div {
    width: 100%;
  }

  .fullwidth {
    width: 100%;
    padding-right: 1.2rem;
    padding-bottom: 16rem;
  }

  .formFooter {
    padding-right: 1.2rem;
  }

  .usergroup-toggleContainer {
    justify-content: flex-start;
    /* padding-left: 0.5rem; */
    top: 83%;
    display: flex;
    position: relative;
    margin-left: 1rem;
    margin-top: 90px;
    margin-bottom: 20px !important;
  }

  .customusergroupnote {
    padding: 20px 20px 10px 20px;
  }
  .users-results-group {
    max-height: 95px;
}
  
}