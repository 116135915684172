.member-dropdown {
    position: relative;
    max-width: 400px;
    left: 80px;
    z-index: 5;
}

.membername_and_avatar {
    display: flex;
    align-items: center;
    padding: 5px;
    height: 35px;

}

.users-avatar {
    position: relative;
    border-radius: 50px;
    width: 25px;
    height: 25px;
}
.users-avatar-mdropdown{
    position: relative;
    border-radius: 50px;
    width: 22px;
    height: 22px;
}


.mdropdown-header {
    padding: 8px;
    background-color: #f2f2f2;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: end;
    min-width: 200px;
    gap: 5px;


}

.mdropdown-header span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 260px;
    font-weight: 700;
}

.mdropdown-icon {
    font-size: 1.2rem;
    color: #333;

}

.mdropdown-options {
    width: 170%;
    max-height: 170px !important;
    /* Adjust the height as needed */
    overflow-y: auto;
    /* Enable vertical scrolling */
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.mdropdown-option {
    padding: 1px;
    padding-top: 3px;
    cursor: default;
    transition: background-color 0.3s;
    color: #333;
    font-size: 13px;
    z-index: 1;
}

.mdropdown-option:hover {
    background-color: #eaeaea;
}

.mdropdown-option.selected {
    background-color: #edebe9;
    /* Medium grey */
    color: #000;
    /* White color */
}

.msearch-container {
    position: relative;
    height: 30px;
    margin-top: 2px;
    left: 0;
    width: 170%;
}


.msearch-container input {
    width: 100%;
    padding: 5px 20px 5px 10px;
    border: 1px solid #ddd;
    line-height: 20px;
    font-size: 13px;
}

.mclear-search-icon {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #666;
    /* Grey color */
}

.mdropdown-loader .ui-loader__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mdropdown-loader svg {
    height: 15px;
    width: 15px;
}

.msearch-container input[type="text"]:focus {
    outline: none !important;
    border-color: #ddd;
    box-shadow: 0 0 10px #ddd;
}

.mHintMsg {
    padding: 8px;
    background: #fff;
    border: 1px solid #ddd;
    width: 170%;
    font-size: 13px;
}
.member-dropdown .member-dropdown-body {
    position: absolute; 
    width: 100%
}
.dropdown-top {
    bottom: 29px !important;
}
@media only screen and (min-width: 320px) and (max-width: 500px) {
    .msearch-container input[type="text"] {
        font-size: 14px;
        width: 94% !important;
    }

    .mdropdown-options {
        width: 160%;
        max-height: 175px !important;
        /* Adjust the height as needed */
        overflow-y: auto;
        /* Enable vertical scrolling */
        background-color: #fff;
        border: 1px solid #ddd;
        /* border-radius: 4px; */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
    }   
    .mHintMsg{
        width: 160% !important;    
    }
    .mclear-search-icon {
       
        right: 16px !important;
       
    }

    .member-dropdown {
        max-width: 150px;
    }

    .mdropdown-header span {
        max-width: 150px;
    }

    .mdropdown-header {
        min-width: 150px;
    }
}